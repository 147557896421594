import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Wrapper from '../layout/wrapper';
import BaseLayout from '../layout/Base/layout';
import FullscreenLayout from '../layout/Fullscreen/layout';

import BaseContainer from '../layout/Base/base-container';
import MaximumContainer from '../layout/Base/maximum-container';

const router = new Router({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('@/pages/login/index'),
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import('@/pages/logout/index'),
        },

        // Xero OAUTH
        /////////////////////////

        {
            path: '/xero',
            name: 'xero',
            component: () => import('@/pages/xero/index'),
        },

        // Bitbucket OAUTH
        /////////////////////////

        {
            path: '/bitbucket',
            name: 'bitbucket',
            component: () => import('@/pages/bitbucket/index'),
        },

        // Atlassian OAUTH
        /////////////////////////

        {
            path: '/atlassian',
            name: 'atlassian',
            component: () => import('@/pages/atlassian/index'),
        },

        // Hubspot OAUTH
        /////////////////////////

        {
            path: '/hubspot',
            name: 'hubspot',
            component: () => import('@/pages/hubspot/index'),
        },

        // Sonos OAUTH
        /////////////////////////

        {
            path: '/sonos',
            name: 'sonos',
            component: () => import('@/pages/sonos/index'),
        },

        // Client access
        /////////////////////////

        {
            path: '/access/:project_ref',
            name: 'access_project',
            component: () => import('@/pages/access/index'),
        },
        {
            path: '/access/error',
            name: 'access_error',
            component: () => import('@/pages/access/error'),
        },

        // Home
        /////////////////////////

        {
            path: '/',
            component: Wrapper,
            children: [
                {
                    path: '/',
                    component: BaseLayout,
                    children: [
                        {
                            path: '/',
                            component: BaseContainer,
                            children: [
                                // Home
                                /////////////////////////

                                // Default page redirect
                                {
                                    path: '/',
                                    name: 'home',
                                    redirect: '/todo',
                                },
                                {
                                    path: '/todo',
                                    name: 'home_todo',
                                    component: () =>
                                        import('@/pages/home/index'),
                                    meta: {
                                        tab: '_todo',
                                    },
                                },
                                {
                                    path: '/events',
                                    name: 'home_events',
                                    component: () =>
                                        import('@/pages/home/index'),
                                    meta: {
                                        tab: '_events',
                                    },
                                },
                                {
                                    path: '/team',
                                    name: 'home_team',
                                    component: () =>
                                        import('@/pages/home/index'),
                                    meta: {
                                        tab: '_team',
                                    },
                                },

                                // Projects
                                /////////////////////////

                                {
                                    path: '/projects',
                                    name: 'projects',
                                    component: () =>
                                        import('@/pages/projects/list'),
                                    meta: {title: 'PROJECTS'},
                                },
                                {
                                    path: '/project/new',
                                    name: 'project_new',
                                    component: () =>
                                        import('@/pages/projects/new'),
                                    meta: {
                                        title: 'NEW PROJECT',
                                        back_to: 'projects',
                                    },
                                },
                                // Default page redirect
                                {
                                    path: '/project/:project_id',
                                    name: 'project_detail',
                                    redirect: '/project/:project_id/overview',
                                },
                                // Overview
                                {
                                    path: '/project/:project_id/overview',
                                    name: 'project_detail_overview',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'projects',
                                        tab: '_overview',
                                    },
                                },
                                // Components
                                {
                                    path: '/project/:project_id/components',
                                    name: 'project_detail_components',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'projects',
                                        tab: '_components',
                                    },
                                },
                                // Estimates
                                {
                                    path: '/project/:project_id/estimates',
                                    name: 'project_detail_estimates',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'projects',
                                        tab: '_estimates',
                                    },
                                },
                                {
                                    path: '/project/:project_id/estimate/new',
                                    name: 'estimate_new',
                                    component: () =>
                                        import('@/pages/estimates/new'),
                                    meta: {
                                        title: 'NEW ESTIMATE',
                                        back_to: 'project_detail_estimates',
                                    },
                                },
                                {
                                    path:
                                        '/project/:project_id/estimate/:estimate_id',
                                    name: 'project_detail_estimate+detail',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail_estimates',
                                        tab: '_estimates+detail',
                                    },
                                },
                                {
                                    path:
                                        '/project/:project_id/estimate/:estimate_id/componentslinking',
                                    name:
                                        'project_detail_estimate+componentslinking',
                                    component: () =>
                                        import(
                                            '@/pages/estimates/componentslinking'
                                        ),
                                    meta: {
                                        key: 'project.name',
                                        back_to:
                                            'project_detail_estimate+detail',
                                    },
                                },
                                {
                                    path: '/estimate/template/new',
                                    name: 'estimate_template_new',
                                    component: () =>
                                        import('@/pages/estimates/new'),
                                    meta: {
                                        title: 'NEW ESTIMATE TEMPLATE',
                                        is_template: true,
                                        back_to: 'management_estimates',
                                    },
                                },
                                {
                                    path: '/estimate/template/:estimate_id',
                                    name: 'estimate_template_edit',
                                    component: () =>
                                        import('@/pages/estimates/detail'),
                                    meta: {
                                        title: 'EDIT ESTIMATE TEMPLATE',
                                    },
                                },
                                // Payment Plans
                                {
                                    path:
                                        '/project/:project_id/paymentplan/:estimate_id',
                                    name: 'project_detail_paymentplan',
                                    component: () =>
                                        import('@/pages/paymentplans/index'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail_estimates',
                                        tab: '_estimates+detail',
                                    },
                                },
                                {
                                    path:
                                        '/project/:project_id/paymentplan/:plan_id',
                                    name: 'project_detail_paymentplan_plan',
                                    component: () =>
                                        import('@/pages/paymentplans/plan'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail_estimates',
                                        tab: '_estimates+detail',
                                    },
                                },
                                {
                                    path:
                                        '/project/:project_id/paymentplan/invoice/:invoice_id',
                                    name: 'project_detail_paymentplan_invoice',
                                    component: () =>
                                        import('@/pages/paymentplans/invoice'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail_estimates',
                                        tab: '_estimates+detail',
                                    },
                                },
                                // Change requests
                                {
                                    path:
                                        '/project/:project_id/changerequest/new',
                                    name: 'changerequest_new',
                                    component: () =>
                                        import('@/pages/changerequests/new'),
                                    meta: {
                                        title: 'NEW CR',
                                        back_to:
                                            'project_detail_changerequests',
                                    },
                                },
                                {
                                    path: '/project/:project_id/changerequests',
                                    name: 'project_detail_changerequests',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_changerequests',
                                    },
                                },
                                {
                                    path:
                                        '/project/:project_id/changerequest/:change_request_id',
                                    name:
                                        'project_detail_changerequests+detail',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_changerequests+detail',
                                    },
                                },
                                // Timeline
                                {
                                    path: '/project/:project_id/timeline',
                                    name: 'project_detail_timeline',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_timeline',
                                    },
                                },
                                // Events
                                {
                                    path: '/project/:project_id/events/new',
                                    name: 'events_new',
                                    component: () =>
                                        import('@/pages/events/new'),
                                    meta: {
                                        title: 'NEW EVENT',
                                        back_to: 'project_detail_timeline',
                                    },
                                },
                                {
                                    path: '/event/:event_id',
                                    name: 'events_update',
                                    component: () =>
                                        import('@/pages/events/new'),
                                    meta: {
                                        title: 'UPDATE EVENT',
                                        back_to: 'planner_calendar',
                                    },
                                },
                                // Utils
                                {
                                    path: '/project/:project_id/utils',
                                    name: 'project_detail_utils',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_utils',
                                    },
                                },
                                {
                                    path: '/project/:project_id/documents',
                                    name: 'project_detail_documents',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_documents',
                                    },
                                },
                                // Notes - DEPRECATED
                                {
                                    path: '/project/:project_id/notes',
                                    name: 'project_detail_notes',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_notes',
                                    },
                                },
                                // Settings
                                {
                                    path: '/project/:project_id/settings',
                                    name: 'project_detail_settings',
                                    component: () =>
                                        import('@/pages/projects/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_settings',
                                    },
                                },

                                // Stages
                                /////////////////////////

                                {
                                    path:
                                        '/project/:project_id/stage/:stage_number/new',
                                    name: 'stage_new',
                                    component: () =>
                                        import('@/pages/stages/new'),
                                    meta: {
                                        title: 'NEW STAGE',
                                        back_to: 'project_detail',
                                    },
                                },
                                // Default page redirect
                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id',
                                    name: 'stage_detail',
                                    redirect:
                                        '/project/:project_id/stage/:stage_id/overview',
                                },
                                // Overview
                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id/overview',
                                    name: 'stage_detail_overview',
                                    component: () =>
                                        import('@/pages/stages/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_overview',
                                    },
                                },
                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id/settings',
                                    name: 'stage_detail_settings',
                                    component: () =>
                                        import('@/pages/stages/detail'),
                                    meta: {
                                        key: 'project.name',
                                        back_to: 'project_detail',
                                        tab: '_settings',
                                    },
                                },

                                // Component
                                /////////////////////////

                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id/component/new',
                                    name: 'component_new',
                                    component: () =>
                                        import('@/pages/components/new'),
                                    meta: {
                                        title: 'NEW COMPONENT',
                                        back_to: 'project_detail',
                                    },
                                },
                                // Default page redirect
                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id/component/:component_id',
                                    name: 'component_detail',
                                    redirect:
                                        '/project/:project_id/stage/:stage_id/component/:component_id/overview',
                                },
                                // Overview
                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id/component/:component_id/overview',
                                    name: 'component_detail_overview',
                                    component: () =>
                                        import('@/pages/components/detail'),
                                    meta: {
                                        key: 'component.name',
                                        back_to: 'project_detail',
                                        tab: '_overview',
                                    },
                                },
                                // Activity
                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id/component/:component_id/activity',
                                    name: 'component_detail_activity',
                                    component: () =>
                                        import('@/pages/components/detail'),
                                    meta: {
                                        key: 'component.name',
                                        back_to: 'project_detail',
                                        tab: '_activity',
                                    },
                                },
                                // Settings
                                {
                                    path:
                                        '/project/:project_id/stage/:stage_id/component/:component_id/settings',
                                    name: 'component_detail_settings',
                                    component: () =>
                                        import('@/pages/components/detail'),
                                    meta: {
                                        key: 'component.name',
                                        back_to: 'project_detail',
                                        tab: '_settings',
                                    },
                                },

                                // Experimental
                                /////////////////////////

                                {
                                    path: '/experimental/editor',
                                    name: 'experimental_editor',
                                    component: () =>
                                        import('@/pages/experimental/editor'),
                                },
                                {
                                    path: '/experimental/todos/:project_id',
                                    name: 'experimental_todos',
                                    component: () =>
                                        import('@/pages/experimental/todos'),
                                },

                                // Modules
                                /////////////////////////

                                {
                                    path: '/project/:project_id/module/new',
                                    name: 'module_new',
                                    component: () =>
                                        import('@/pages/modules/new'),
                                    meta: {
                                        title: 'NEW MODULE',
                                        back_to: 'project_detail_settings',
                                    },
                                },

                                {
                                    path:
                                        '/project/:project_id/module/:module_id/detail',
                                    name: 'module_detail',
                                    component: () =>
                                        import('@/pages/modules/detail'),
                                    meta: {
                                        title: 'MODULE DETAIL',
                                        back_to: 'project_overview',
                                    },
                                },

                                {
                                    path:
                                        '/project/:project_id/module/:module_id/settings',
                                    name: 'module_settings',
                                    component: () =>
                                        import('@/pages/modules/settings'),
                                    meta: {
                                        title: 'MODULE SETTINGS',
                                        back_to: 'project_detail_settings',
                                    },
                                },

                                // Sessions
                                /////////////////////////

                                {
                                    path: '/sessions',
                                    component: () =>
                                        import('@/pages/sessions/index'),
                                    meta: {title: 'SESSIONS'},
                                },

                                // Management
                                /////////////////////////

                                {
                                    path: '/manage',
                                    redirect: '/manage/invoices',
                                    component: () =>
                                        import('@/pages/management/index'),
                                    meta: {title: 'MANAGEMENT'},
                                    children: [
                                        // Invoices
                                        /////////////////////////

                                        {
                                            path: '/manage/invoices',
                                            name: 'management_invoices',
                                            component: () =>
                                                import(
                                                    '@/pages/management/invoices'
                                                ),
                                            meta: {
                                                tab: '_invoices',
                                            },
                                        },
                                        {
                                            path:
                                                '/manage/invoice/:invoice_number',
                                            name: 'management_invoices_detail',
                                            component: () =>
                                                import(
                                                    '@/pages/management/invoices/detail'
                                                ),
                                            meta: {
                                                tab: '_invoices',
                                            },
                                        },

                                        // Clients
                                        /////////////////////////

                                        {
                                            path: '/manage/clients',
                                            name: 'management_clients',
                                            component: () =>
                                                import(
                                                    '@/pages/management/clients'
                                                ),
                                            meta: {
                                                tab: '_clients',
                                            },
                                        },
                                        {
                                            path: '/manage/client/new',
                                            name: 'management_client_new',
                                            component: () =>
                                                import(
                                                    '@/pages/management/clients/new'
                                                ),
                                            meta: {
                                                tab: '_clients',
                                            },
                                        },
                                        {
                                            path: '/manage/client/:client_id',
                                            name: 'management_client_detail',
                                            component: () =>
                                                import(
                                                    '@/pages/management/clients/detail'
                                                ),
                                            meta: {
                                                tab: '_clients',
                                            },
                                        },

                                        // Client -> Project
                                        /////////////////////////

                                        {
                                            path:
                                                '/manage/client/:client_id/project/link',
                                            name:
                                                'management_client_project_link',
                                            component: () =>
                                                import(
                                                    '@/pages/management/clients/project/link'
                                                ),
                                            meta: {
                                                tab: '_clients',
                                            },
                                        },

                                        // Projects
                                        /////////////////////////

                                        {
                                            path: '/manage/projects',
                                            name: 'management_projects',
                                            component: () =>
                                                import(
                                                    '@/pages/management/projects'
                                                ),
                                            meta: {
                                                tab: '_projects',
                                            },
                                        },
                                        {
                                            path: '/manage/project/new',
                                            name: 'management_project_new',
                                            component: () =>
                                                import(
                                                    '@/pages/management/projects/new'
                                                ),
                                            meta: {
                                                tab: '_projects',
                                            },
                                        },
                                        {
                                            path: '/manage/project/:project_id',
                                            name: 'management_project_detail',
                                            component: () =>
                                                import(
                                                    '@/pages/management/projects/detail'
                                                ),
                                            meta: {
                                                tab: '_projects',
                                            },
                                        },

                                        // Estimates
                                        /////////////////////////

                                        {
                                            path: '/manage/estimates',
                                            name: 'management_estimates',
                                            component: () =>
                                                import(
                                                    '@/pages/management/estimates'
                                                ),
                                            meta: {
                                                tab: '_estimates',
                                            },
                                        },

                                        // Payment plans
                                        /////////////////////////

                                        {
                                            path: '/manage/paymentplans',
                                            name: 'management_payment_plans',
                                            component: () =>
                                                import(
                                                    '@/pages/management/paymentplans'
                                                ),
                                            meta: {
                                                tab: '_paymentplans',
                                            },
                                        },

                                        // Templates
                                        /////////////////////////

                                        {
                                            path: '/manage/templates',
                                            name: 'management_templates',
                                            component: () =>
                                                import(
                                                    '@/pages/management/templates'
                                                ),
                                            meta: {
                                                tab: '_templates',
                                            },
                                        },

                                        // Users
                                        /////////////////////////

                                        {
                                            path: '/manage/users',
                                            name: 'management_users',
                                            component: () =>
                                                import(
                                                    '@/pages/management/users'
                                                ),
                                            meta: {
                                                tab: '_users',
                                            },
                                        },
                                        {
                                            path: '/manage/user/new',
                                            name: 'management_users_new',
                                            component: () =>
                                                import(
                                                    '@/pages/management/users/new'
                                                ),
                                            meta: {
                                                tab: '_users',
                                                title: 'NEW USER',
                                                back_to: 'management_users',
                                            },
                                        },
                                        {
                                            path: '/manage/users/:user_id',
                                            name: 'management_users_detail',
                                            component: () =>
                                                import(
                                                    '@/pages/management/users/detail'
                                                ),
                                            meta: {
                                                tab: '_users',
                                            },
                                        },
                                    ],
                                },

                                // Accounts
                                /////////////////////////

                                // {
                                //     path: '/accounts',
                                //     name: 'accounts',
                                //     component: () => import('@/pages/accounts/list'),
                                //     meta: {title: 'ACCOUNTS'},
                                // },
                                // {
                                //     path: '/account/:account_id',
                                //     name: 'account_detail',
                                //     component: () => import('@/pages/accounts/detail'),
                                //     meta: {title: 'ACCOUNT', back_to: 'accounts'},
                                // },

                                // Business
                                /////////////////////////

                                // {
                                //     path: '/business',
                                //     name: 'business',
                                //     component: () => import('@/pages/business/index'),
                                //     meta: {title: 'BUSINESS'}
                                // }
                            ],
                        },
                        {
                            path: '/',
                            component: MaximumContainer,
                            children: [
                                // Planner
                                /////////////////////////

                                // Default page redirect
                                {
                                    path: '/planner',
                                    name: 'planner',
                                    redirect: '/planner/timeline',
                                },
                                {
                                    path: '/planner/timeline',
                                    name: 'planner_timeline',
                                    component: () =>
                                        import('@/pages/planner/index'),
                                    meta: {
                                        title: 'TIMELINE',
                                        key: 'planner',
                                        tab: '_timeline',
                                    },
                                },
                                {
                                    path: '/planner/calendar',
                                    name: 'planner_calendar',
                                    component: () =>
                                        import('@/pages/planner/index'),
                                    meta: {
                                        title: 'CALENDAR',
                                        key: 'planner',
                                        tab: '_calendar',
                                    },
                                },
                                {
                                    path: '/planner/schedule',
                                    name: 'planner_schedule',
                                    component: () =>
                                        import('@/pages/planner/index'),
                                    meta: {
                                        title: 'TIMELINE',
                                        key: 'planner',
                                        tab: '_schedule',
                                    },
                                },

                                // Reports
                                /////////////////////////

                                // Default page redirect
                                {
                                    path: '/reports',
                                    name: 'reports',
                                    redirect: '/report/cashflow',
                                },
                                {
                                    path: '/report/cashflow',
                                    name: 'report_cashflow',
                                    component: () =>
                                        import('@/pages/reports/index'),
                                    meta: {
                                        title: 'REPORTS',
                                        key: 'cashflow',
                                        tab: '_cashflow',
                                    },
                                },
                                {
                                    path: '/report/profitloss',
                                    name: 'report_profitloss',
                                    component: () =>
                                        import('@/pages/reports/index'),
                                    meta: {
                                        title: 'REPORTS',
                                        key: 'profitloss',
                                        tab: '_profitloss',
                                    },
                                },
                            ],
                        },
                    ],
                },

                // Fullscreen
                /////////////////////////
                {
                    path: '/',
                    component: FullscreenLayout,
                    children: [
                        // Documents
                        /////////////////////////

                        {
                            path: '/document/:doc_type/template/new',
                            name: 'document_template_new',
                            component: () => import('@/pages/documents/index'),
                        },
                        {
                            path: '/document/:doc_type/template/:template_id',
                            name: 'document_template_edit',
                            component: () => import('@/pages/documents/index'),
                        },
                        {
                            path: '/document/:doc_type/new/:ref_id',
                            name: 'document_new',
                            component: () => import('@/pages/documents/index'),
                        },
                        {
                            path: '/document/:doc_id',
                            name: 'document_edit',
                            component: () => import('@/pages/documents/index'),
                        },
                    ],
                },

                {
                    path: '/tv/team',
                    name: 'tv',
                    component: () => import('@/pages/tv/index'),
                },

                {
                    path: '/convert',
                    name: 'convert',
                    component: () => import('@/pages/convert/index'),
                },
            ],
        },

        {
            path: '*',
            redirect: '/',
        },
    ],
});

export default router;
